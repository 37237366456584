import { createWeb3Modal, defaultWagmiConfig } from "@web3modal/wagmi/react";
import { bsc, bscTestnet } from "viem/chains";

const projectId = "69dae2683f10fcf6d981b3846d4f03b6";
const BNB_API_KEY = "FZ9TGE7XCY32G7YR7BDDBJ211CF7DMFF2G";

const LIST_ENVIRONMENT = ["STAGING", "STABLE", "PRODUCTION"];
const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT;

if (!LIST_ENVIRONMENT.includes(ENVIRONMENT)) {
  throw Error(`UNSUPPORTED ENVIRONMENT ${ENVIRONMENT}`);
}

const metadata = {
  name: "IVIRSE PTE Co., LTD",
  description: "IVIRSE DigiHealth Ecosystem",
  url: "https://wallet.ivirse.com",
  icons: ["https://ivirse.com/static/images/logo.png"],
};
bsc.rpcUrls.default.http = [
  "https://bsc-dataseed1.binance.org",
  "https://bsc-dataseed2.binance.org",
  "https://bsc-dataseed3.binance.org",
  "https://bsc-dataseed4.binance.org",
  "https://bsc-dataseed1.defibit.io",
  "https://bsc-dataseed2.defibit.io",
  "https://bsc-dataseed3.defibit.io",
  "https://bsc-dataseed4.defibit.io",
  "https://bsc-dataseed1.ninicoin.io",
  "https://bsc-dataseed2.ninicoin.io",
  "https://bsc-dataseed3.ninicoin.io",
  "https://bsc-dataseed4.ninicoin.io",
];
bsc.rpcUrls.public.http = [
  "https://bsc-dataseed1.binance.org",
  "https://bsc-dataseed2.binance.org",
  "https://bsc-dataseed3.binance.org",
  "https://bsc-dataseed4.binance.org",
  "https://bsc-dataseed1.defibit.io",
  "https://bsc-dataseed2.defibit.io",
  "https://bsc-dataseed3.defibit.io",
  "https://bsc-dataseed4.defibit.io",
  "https://bsc-dataseed1.ninicoin.io",
  "https://bsc-dataseed2.ninicoin.io",
  "https://bsc-dataseed3.ninicoin.io",
  "https://bsc-dataseed4.ninicoin.io",
];
const CONFIG_BY_ENVIRONMENT = {
  STAGING: {
    name: "bnbt",
    address: "0x009eC8014598e02Cc936ed0F897642d92fa3890F",
    scanURL: "https://api-testnet.bscscan.com",
    chainId: "0x61",
    API_KEY: BNB_API_KEY,
    symbol: "BNBT",
    nameCoin: "BNB smart chain testnet",
    reactiveContractAddress: "0x80dD54B9509d288Db6411E3a8cb9dc8EEE0b7374",
    marketplaceContractAddress: "0x2ad2C86AD52E5040375073dAD76654b8643A2843",
    intergrationServer: "https://intergration.isofh.vn",
    tokenManagementAddress: "0xE3284bca37a99532047739Bb1a55ae391c56BE94",
    adminManagement: "0x096e437d0aF2eAc385d30e0342a5c101204F8344",
    voteFactory: "0x906FE858Ec2386C5A78449c23207c531C6cC384A",
    campaignManagement: "0xf30a40E83cDC6193a323E2fBd462F07878dF51Ac",
    merchantFactory: "0x4dfFee64Ec389bF3472EB34EE847C911dd7E019a",
    claimTokenFactory: "0xAf2fe9e26eE99eE896456e67E3f1cA03eCd2F48a",
    chains: [bscTestnet],
  },
  STABLE: {
    name: "bnb",
    address: "0xD5944d6F633ac50a668479974b08ee1189cF86FD",
    scanURL: "https://api.bscscan.com",
    chainId: "0x38",
    API_KEY: BNB_API_KEY,
    symbol: "BNB",
    nameCoin: "BNB smart chain",
    reactiveContractAddress: "0x1efF925F0D8562926BE3d4907c882D7726376480",
    marketplaceContractAddress: "0xFA609f6ad4bF064FD84D0DF95Ff0538FED7dA48C",
    intergrationServer: "https://intergration.isofh.vn",
    tokenManagementAddress: "0xa7cB1aFa0e2aAe81aA78358BCE7873F67fCecC9a",
    adminManagement: "0x9dE9c5D4Fb470ad7d66b801e51Ed977F7F17157C",
    voteFactory: "0xb90F6141D08A4cdaE4D60Fc81985303c3A2AdDEa",
    campaignManagement: "0xaad5aa0541666E53C53461b5094cFFfF7D4C96F2",
    merchantFactory: "0xD1B388C8a45585237f76A2acF31F204F65CE38F4",
    claimTokenFactory: "0x7B95425fcD8315EBF91Beb46b26e913A2f1a8270",
    chains: [bsc],
  },
  PRODUCTION: {
    name: "bnb",
    address: "0x059ca11ba3099683Dc2e46f048063F5799a7f34c",
    scanURL: "https://api.bscscan.com",
    chainId: "0x38",
    API_KEY: BNB_API_KEY,
    symbol: "BNB",
    nameCoin: "BNB smart chain",
    communityAddress: "0x209D3752CD304052dF3E5C956c5beaf68bE6d200",
    reactiveContractAddress: "0x0628A75c0a2077B434f9f5B2139EE8Ff40a17c00",
    marketplaceContractAddress: "0xa12966A006C6aF37AE50ab768F962Ca5858064Ba",
    intergrationServer: "https://integration.ivirse.com",
    tokenManagementAddress: "0x092e6b78CDAf4347518e542Ad44DF4b121C2E925",
    adminManagement: "0x905D395606ae45D0E925094BA3c7425348DC520F",
    voteFactory: "0x516C5AFaceFaA4E8cEAe61b364F261De2590C614",
    campaignManagement: "0x4CBF9061E70AD72756d246FDF792F292D89Df8D2",
    stakingManagement: "0x74a159A43A97Bc86966cA3b07B1a95Ca60a75D81",
    claimTokenFactory: "0x2C87b04Ae83b59228278B05073E5d3061891e23e",
    chains: [bsc],
  },
};

export const CURRENT_CONFIG = CONFIG_BY_ENVIRONMENT[ENVIRONMENT];
const chains = CURRENT_CONFIG.chains;

export const wagmiConfig = defaultWagmiConfig({
  chains,
  projectId,
  metadata,
});

createWeb3Modal({ wagmiConfig, projectId, chains });
